<template>
    <div>
        <div class="flex flex-col flex-1 h-full px-4">
            <table class="min-w-full quext-table">
                <thead>
                    <tr>
                        <th class="pl-2">name</th>
                        <th>email</th>
                        <th>phone</th>
                        <th>date</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="contact in contacts" :key="contact.id_website_leads" class="bg-gray-50 odd:bg-white">
                        <td class="flex py-4 pl-2 whitespace-no-wrap items-center justify-start">
                            <div class="text-base leading-5 font-medium text-gray-900">
                                {{ contact.website_leads_name }}
                            </div>
                        </td>
                        <td class="whitespace-no-wrap ">
                            <div class="text-base">
                                {{ contact.email }}
                            </div>
                        </td>
                        <td class="whitespace-no-wrap ">
                            <div class="text-base">
                                {{ formatPhone(contact.phone) }}
                            </div>
                        </td>
                        <td class="whitespace-no-wrap ">
                            <div class="text-base">
                                {{ formatDate(contact.created_at) }}
                            </div>
                        </td>
                        <td class="text-right pr-2">
                            <modal-link
                                route="contacts.show"
                                :params="{ contactId: contact.id_website_leads }"
                            >
                                <div class="text-highlight-textSmall text-sm font-inter font-semibold tracking-wider cursor-pointer">view</div>
                            </modal-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import ModalNavigation from "@/mixins/ModalNavigation";
    import ModalLink from "@/components/ui/modals/ModalLink";
    import {mapActions, mapGetters} from "vuex";
    import moment from 'moment';
    import {formatPhone} from "@/utils/String";

    export default {
        mixins: [ ModalNavigation ],
        components: {ModalLink},
        data: () => {
            return {
                website: {},
            }
        },
        computed: {
            ...mapGetters({
                contacts: 'contacts/getContacts',
                getWebsiteInfo: 'website_info/getWebsiteInfo'
            }),
        },
        methods: {
            ...mapActions({
                loadContactsInStore: 'contacts/loadContacts'
            }),

            formatPhone,

            formatDate(val) {
                return moment(val).format('M/D/YYYY h:mm a');
            },

            getContacts() {
                this.$cmsDataProvider.get('analyticsLeadInfo', { websiteId: this.website.id}).then((response) => {
                    this.loadContactsInStore(response);
                })
            }       
        },
        async mounted() {
            this.website = this.getWebsiteInfo
            this.getContacts();
        },
    }
</script>

<style scoped>
.quext-table {
    @apply font-frank;
}

.quext-table th {
    @apply font-600 text-xs tracking-wide text-left border-b-2 border-gray-500 pb-1;
}

.quext-table tr:hover {
  background-color: var(--highlightColor100) !important;
}

</style>